import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { VcallQuestionTypeBooleanDetail } from '../types/boolean/boolean.component';
import { VcallQuestionTypeClosingDetail } from 'src/app/shared/models/vcall/closingDetail';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { Application } from 'src/app/shared/models/application/application';
import { Answer } from 'src/app/shared/models/application/answer';
import { Question } from 'src/app/shared/models/vcall/question';
import { RecallStatus } from 'src/app/shared/models/recall/RecallStatus';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { RecallInfo } from 'src/app/shared/models/recall/RecallInfo';
import { Recall } from 'src/app/shared/models/recall/Recall';
import { AppointmentAnswerService } from 'src/app/shared/services/appointmentAnswer/appointmentAnswer.service';
import { ProcessingService } from 'src/app/shared/services/processors/processing.service';
import { ProcessorModel } from 'src/app/shared/services/processors/processor-model';
import { LoggerService } from 'src/app/core/logger.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

interface StatusForProcessors {
  status: string;
  image: string;
}

@Component({
  selector: 'app-endcall',
  templateUrl: './endcall.component.html',
  styleUrls: ['./endcall.component.scss'],
})
export class EndcallComponent implements OnInit, OnDestroy {
  @Input() disableBtn;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  details = new VcallQuestionTypeClosingDetail();
  endCallPopupText: string;
  endCallWaitText =
    'Please be patient application is closing the appointment, sending messages to other systems. <br /> If this message persists for more than 30 seconds, please refresh the browser and try again.';
  selectedApplication;
  selectedAppointment;
  application: Application;
  question: Question;
  recalls: Recall[] = [];
  completeCallType: number;
  recallGuid: string = null;
  processorModel: ProcessorModel = {};
  processing = false;
  questionText: string;
  statusOfProcessors: StatusForProcessors[] = [
    {
      status: 'failed',
      image: 'assets/images/failed.svg',
    },
    {
      status: 'completed',
      image: 'assets/images/completed.svg',
    },
    {
      status: 'inProgress',
      image: 'assets/images/inprogress.svg',
    },
  ];
  displayTryAgainButton: boolean = false;
  ignore: boolean = false;

  constructor(
    private modalService: NgbModal,
    private scriptService: ScriptService,
    private appointmentService: AppointmentService,
    private userProfileService: UserProfileService,
    private applicationService: ApplicationService,
    private router: Router,
    private vcallApiService: VcallApiService,
    private appointmentAnswerService: AppointmentAnswerService,
    public processor: ProcessingService,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.scriptService.SelectedQuestion.subscribe((q) => {
        if (q) {
          Object.assign(this.details, q.details);
          this.endCallPopupText = this.details.endCallPopupText;
          this.question = q;
        }
      })
    );

    this.subscriptions.push(
      this.scriptService.ApplicationScript.subscribe((s) => {
        this.processorModel.script = s;
      })
    );

    this.subscriptions.push(
      this.applicationService.Answers.subscribe((a) => {
        this.processorModel.answers = a;
      })
    );

    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((a) => {
        this.selectedAppointment = a;
        this.processorModel.appointment = a;
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a) {
          this.application = a;
          this.processorModel.application = a;
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.ApplicationRecalls.subscribe((r) => {
        if (r) {
          this.recalls = r;
        }
      })
    );

    // updated question text with interpolation all the properties
    this.subscriptions.push(
      this.scriptService.PromptText.subscribe((resp) => {
        if (resp) {
          this.questionText = resp;
        }
      })
    );

    if (this.selectedAppointment.callType !== RecallInfo.callType) {
      this.subscriptions.push(
        this.processor.ProcessingStatus$.subscribe((s) => {
          this.displayTryAgainButton = this.processor.displayTryAgainButton;
        })
      );
    }
  }

  async endCall(content) {
    // Before end the call checking the call is completed or not
    // If not completed proceed the next code
    // If completed showing the completed call popup and stop the next code excution
    let callCompletedOrNot: boolean = false;
    await this.appointmentService
      .callIsCompletedOrNot(this.application.id.toString())
      .then((r) => {
        callCompletedOrNot = r;
      });
    if (callCompletedOrNot) {
      return;
    }
    this.applicationService.RegisterAnswerByTag(
      true,
      true,
      this.question.answerTag,
      null,
      null,
      this.questionText
    );
    this.processing = false;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    // .result.then(async (result) => {
    //   if (result === 'confirm') {
    //     const userProfile = this.userProfileService.getUserProfile();
    //     // TODO: Move this logic to an application processor
    //     if (this.selectedAppointment.callType === RecallInfo.callType) {
    //       const recallData = this.recalls
    //         .filter(
    //           (r) =>
    //             this.selectedAppointment.appointmentId === r.appointmentId
    //         )
    //         .shift();
    //       // logging the objects to logger to trace the error.
    //       if (!recallData) {
    //         const customError: Error = {
    //           name: null,
    //           message: null,
    //         };
    //         customError.name = 'error in recall caomplete';
    //         customError.message =
    //           'recallObject : ' +
    //           recallData +
    //           ', selectedAppointment : ' +
    //           JSON.stringify(this.selectedAppointment) +
    //           ', recallsList : ' +
    //           JSON.stringify(this.recalls);

    //         this.loggerService.logException(customError);
    //         this.toastService.error(
    //           'Oops!',
    //           'Something went wrong, we have noted the cause. Please refresh the page and try again.'
    //         );
    //       }
    //       recallData.status = RecallStatus.completed;
    //       this.recallGuid = recallData.recallId;
    //       recallData.questionList.map(
    //         (q) => (q.status = RecallStatus.completed)
    //       );
    //       this.vcallApiService
    //         .insertRecallQuestions(
    //           recallData,
    //           String(this.selectedAppointment.appId)
    //         )
    //         .subscribe((res) => {
    //           console.log(res);
    //         });
    //     }
    //     // vcall complete or recall complete flag setting
    //     if (this.selectedAppointment.callType !== RecallInfo.callType) {
    //       this.completeCallType = 0;
    //       this.recallGuid = null;
    //     } else {
    //       this.completeCallType = 1;
    //     }

    //     this.scriptService.GoToNextQuestion(this.question);
    //     if (this.scriptService.canCloseSession()) {
    //       const stageResult = await this.processor.RunCompleteStage(
    //         this.processorModel
    //       );

    //       // TODO: Move this logic to an application processor
    //       this.appointmentAnswerService.updateAppointmentStatus(
    //         this.question,
    //         'complete'
    //       );

    //       this.applicationService.GetCallLog(this.application.id.toString());

    //       this.applicationService.PersistAnswers();
    //       // TODO: Move this logic to an application processor
    //       if (userProfile) {
    //         this.appointmentService.EndCallAsCompleted(
    //           this.application,
    //           this.selectedAppointment,
    //           userProfile,
    //           this.completeCallType,
    //           this.recallGuid
    //         );
    //       }
    //       // this.appointmentService.CallLogResult.subscribe(
    //       //   (data) => {
    //       //     this.applicationService.Clear();
    //       //     this.appointmentService.Clear();
    //       //     this.router.navigate(['/'], {skipLocationChange : false, replaceUrl : true, preserveFragment : false});
    //       //     // this.router.navigateByUrl('/', {skipLocationChange : false, replaceUrl : true, preserveFragment : false});
    //       //   },
    //       //   (reason) => {}
    //       // );
    //     }
    //   }
    // });
  }

  async endTheCall() {
    if (this.ignore) {
      sessionStorage.removeItem(this.application.id + '_completeStage');
    }
    this.processorModel.ignoreTheErros = this.ignore;
    this.processorModel.question = this.question;
    this.processing = true;
    const appointment = JSON.parse(JSON.stringify(this.selectedAppointment));
    const userProfile = this.userProfileService.getUserProfile();
    // TODO: Move this logic to an application processor
    if (appointment.callType === RecallInfo.callType) {
      const recallData = this.recalls
        .filter((r) => appointment.appointmentId === r.appointmentId)
        .shift();
      // logging the objects to logger to trace the error.
      if (!recallData) {
        const customError: Error = {
          name: null,
          message: null,
        };
        customError.name = 'error in recall caomplete';
        customError.message =
          'recallObject : ' +
          recallData +
          ', selectedAppointment : ' +
          JSON.stringify(appointment) +
          ', recallsList : ' +
          JSON.stringify(this.recalls);

        this.loggerService.logException(customError);
        this.toastService.error(
          'Oops!',
          'Something went wrong, we have noted the cause. Please refresh the page and try again.'
        );
      }
      recallData.status = RecallStatus.completed;
      this.recallGuid = recallData.recallId;
      recallData.questionList.map((q) => (q.status = RecallStatus.completed));
      await this.upsertRecallQuestions(recallData, appointment);
    }
    let completeCallType;
    // vcall complete or recall complete flag setting
    if (appointment.callType !== RecallInfo.callType) {
      completeCallType = 0;
      this.recallGuid = null;
    } else {
      completeCallType = 1;
    }

    await this.scriptService.GoToNextQuestion(this.question);
    if (this.scriptService.canCloseSession()) {
      this.displayTryAgainButton = false;
      this.processorModel.logger = this.loggerService;
      if (appointment.callType !== RecallInfo.callType) {
        const stageResult = await this.processor.RunCompleteStage(
          this.processorModel
        );
      }
      console.log('next code RunCompleteStage ###################');

      this.applicationService.GetCallLog(this.application.id.toString());

      await this.applicationService.PersistAnswers();
      this.processorModel.userProfile = userProfile;
      this.processorModel['completeCallType'] = completeCallType;
      this.processorModel['recallGuid'] = this.recallGuid;
      let res = await this.processor.Run('CompleteStatus', this.processorModel);
      await this.applicationService.PersistAnswers();
      console.log('next code After CompleteStatus ###################', res);

      this.appointmentService.Clear();
      this.applicationService.Clear();
      this.scriptService.Clear();

      this.router.navigate(['/'], {
        skipLocationChange: false,
        replaceUrl: true,
        preserveFragment: false,
      });
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
      // this.appointmentService.CallLogResult.subscribe(
      //   (data) => {
      //     this.applicationService.Clear();
      //     this.appointmentService.Clear();
      //     this.router.navigate(['/'], {skipLocationChange : false, replaceUrl : true, preserveFragment : false});
      //     // this.router.navigateByUrl('/', {skipLocationChange : false, replaceUrl : true, preserveFragment : false});
      //   },
      //   (reason) => {}
      // );
    } else {
      this.modalService.dismissAll();
    }
  }
  upsertRecallQuestions(recallData, appointment, retries = 2) {
    this.vcallApiService
      .insertRecallQuestions(recallData, String(appointment.appId))
      .subscribe(
        (res) => {
          // Check if response is an error
          if (res instanceof HttpErrorResponse) {
            // Retry logic if needed
            if (retries > 0) {
              this.upsertRecallQuestions(recallData, appointment, retries - 1);
              this.loggerService.logException(res);
            } else {
              console.log('Maximum retries exceeded.');
            }
          } else {
            console.log(res, 22222);
            // Handle successful response here if needed
          }
        },
        (error) => {
          // Handle error if it was not recognized as an HttpErrorResponse
          console.error('An error occurred:', error);

          // Retry logic if needed
          if (retries > 0) {
            console.log(`Retrying... (${retries} attempts left)`);
            this.upsertRecallQuestions(recallData, appointment, retries - 1);
            this.loggerService.logException(error);
          } else {
            console.log('Maximum retries exceeded.');
          }
        }
      );
  }

  exportErrors() {
    console.log('this.processor.defaultError', this.processor.defaultError);
    const appointment = JSON.parse(JSON.stringify(this.selectedAppointment));
    let errors = this.processor.defaultError.map((eachError) => {
      let error = {};
      error['message'] = Array.isArray(eachError)
        ? eachError[0].message
        : eachError.message;
      error['stack'] = Array.isArray(eachError)
        ? eachError[0].stack
        : eachError.stack;
      error['payload'] = Array.isArray(eachError)
        ? eachError[0].payload
        : eachError.payload;
      error['url'] = Array.isArray(eachError)
        ? eachError[0].url
        : eachError.url;
      error['questionId'] = this.question.id;
      error['sectionName'] = this.question.section.name;
      error['appId'] = this.application.id;
      error['appointmentId'] = appointment.appointmentId;
      error['verifierName'] = appointment.username;
      return (eachError = error);
    });
    const jsonString = JSON.stringify(errors, null, 2);
    const blob = new Blob([jsonString], { type: 'text/plain' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'error.txt';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // Processor stauts images display
  getStatusPillImagePath(status: string) {
    return this.statusOfProcessors.filter((s) => s.status === status)[0]?.image;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
