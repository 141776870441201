<app-browser-compatability-check></app-browser-compatability-check>
<app-errors-list></app-errors-list>
<app-nav-header></app-nav-header>
<div class="container-fluid m-0 p-0">
  <router-outlet></router-outlet>
  <app-toasts></app-toasts>
</div>

<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'msg',
    submitButtonName: 'Close'
  }"
  (actions)="okClickOnPermissionModal(modalParameters)"
>
  <div title>
    <h4 class="modal-title" id="modal-basic-title">
      <span> {{ modalParameters.header }} </span>
    </h4>
  </div>
  <div body>
    <div [innerHTML]="modalParameters.body"></div>
    <div *ngIf="modalParameters.modalBehavior === 2">
      On "Close" the page will refresh itself.
    </div>
  </div>
</app-modal>
<div class="scroll-totop-button btn-sm bg-light" (click)="scrollToTop()">
  <a
    target="_blank"
    data-toggle="tooltip"
    data-placement="left"
    title="Scroll to top"
  >
    <i class="fas fa-chevron-up up-arrow-icon" aria-hidden="true"></i>
  </a>
</div>
<div class="user-guide-button btn-sm bg-light">
  <a
    href="userguide"
    target="_blank"
    data-toggle="tooltip"
    data-placement="left"
    title="User Guide"
  >
    <i class="fa fa-question-circle" aria-hidden="true"></i>
  </a>
  <span class="version">{{ version }}</span>
</div>
<!-- when the user is idle for 15 min's -->
<app-user-idle-tracker></app-user-idle-tracker>
<!-- when the user is idle for 15 min's -->
