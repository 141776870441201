import { Injectable } from '@angular/core';
import { ProcessorModel } from '../processor-model';
import { Processor } from '../processor';
import { ProcessingResult } from '../processing-result';
import { NbcApiService } from 'src/app/shared/services/nbc/nbc-api.service';
import { UserProfileService } from '../../user/user-profile.service.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ApplicationChangeProcessor implements Processor {
  name = 'Notifying AS400';
  mustWaitForFinish: boolean;
  stopIfError: any;
  hasStopProcessing: boolean = false;
  contactZipCode = 'contact.zipCode';
  contactState = 'contact.state';

  constructor(
    private nbcService: NbcApiService,
    private userProfileService: UserProfileService
  ) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let self = this;
    const httpSuccess: number = 200;
    const changedValues = this.processChanges(model);
    var postEventReponse;
    this.hasStopProcessing = false;

    var zipOrStateChanged = _.some(changedValues, (x: any) => x.attributeName === self.contactZipCode
                                                              || x.attributeName === self.contactState);

    // as400 processor will only send request when theres no change to either zipcode or state.
    if(changedValues.length > 0 && !zipOrStateChanged) {
      let userProfile = this.userProfileService.getUserProfile();
      let userName = userProfile.domainUserName;
      let applicationId = model.application.id.toString(); 
      var publisher = 'VCALL';

      var changeData = {
        ApplicationId: applicationId,
        Publisher: publisher,
        User: userName,
        ApplicationURL: window.location.origin,
        ChangeItems: changedValues
      };

      const payload = {
        Type: 'USHG.NewBusiness.StatusChanged.ContactChanged',
        DateUtc: new Date().toISOString(),
        Data: changeData,
        Publisher: publisher,
        Id: applicationId,
        User: userName,
      };

      postEventReponse = await this.nbcService.postEvent(payload);

      this.hasStopProcessing = postEventReponse.status !== httpSuccess;
    }

    return Promise.resolve({
      result: true,
      stopProcessing: this.hasStopProcessing,
    });
  }

  private processChanges(model: ProcessorModel): any[] {
    let self = this;

    const monitorProp = [
      'contact.streetAddress',
      'contact.city',
      self.contactZipCode,
      self.contactState,
      'contact.email',
      'contact.homePhone',
      'contact.workPhone'
    ];

    let changedValues = [];

    monitorProp.forEach((path) => {
      const parts = path.split('.');

      this.compareAndAddChanges(
        model,
        parts[0],
        parts[1],
        changedValues
      );
    });

    return changedValues;
  }

  private compareAndAddChanges(
    model: ProcessorModel,
    key: string,
    subKey: string | null,
    changedValues: any[]
  ) {
    const answerTag = subKey ? `${key}.${subKey}` : key;
    const answer = model.answers.find(
      (a) => a.answerTag === answerTag
    );
    
    if (answer) {
      let oldValue, newValue;

      oldValue = answer.ezAppValue;
      newValue = answer.value;
      
      if (oldValue !== newValue && !_.isEmpty(newValue)) {
        changedValues.push({
          oldvalue: oldValue,
          newvalue: newValue,
          attributeName: answerTag,
        });
      }
    }
  }
}
