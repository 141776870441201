import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { Script } from 'src/app/shared/models/vcall/script';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from '../handledHttpClient';
import { DetailQuestion } from 'src/app/shared/models/vcall/detailQuestion';
import { ApplicationNote } from 'src/app/shared/models/vcall/applicationNote';
import { StringToEncrypt } from 'src/app/vcall/questions/types/verifiableList/verifiable-list.component';
import { Recall } from 'src/app/shared/models/recall/Recall';
import { ApplicationReviewItem } from 'src/app/underwriting/models/review-item';
import { ApiAnswer } from '../../application/application.service';
import { ToastService } from '../../toast/toast.service';
import { ProcessorModel } from '../../processors/processor-model';
import { ScriptVersion } from '../../script/script.service';

@Injectable({
  providedIn: 'root',
})
export class VcallApiService {
  private baseUrl: string;

  constructor(
    private http: HandledHttpClient,
    private toastServ: ToastService
  ) {
    this.baseUrl = environment.config.apis.vcall;
  }

  /**
   * @description get the questions based on the route level from the cosmos db
   */
  public GetScript(
    application: VcallParams,
    scriptVersion?: ScriptVersion
  ): Observable<Script> {
    if (application.mode === 'Recall') {
      return this.http.get<Script>(
        `${this.baseUrl}/Application/${application.applicationId}/Recall/${application.appointmentId}`
      );
    } else {
      const version = scriptVersion ? '/' + scriptVersion.startDate : '';
      return this.http.get<Script>(
        `${this.baseUrl}/Application/${application.applicationId}/script${version}`
      );
    }
  }

  /**
   * @param appId pass the selected applicationId
   * @description get application details with respective selected appId
   */
  public GetApplication(applicationId: string): Observable<Application> {
    // applicationId = '4002382';
    return this.http.get<Application>(
      this.baseUrl + '/application/' + encodeURIComponent(applicationId),
      {
        errorMessage: `Error retrieving application from the service for id = '${applicationId}'`,
        responseDecider: 'application',
      }
    );
  }

  /**
   * @param appId pass the selected applicationId
   * @description export the  application details with respective appId
   */
  public GetApplicationExport(
    applicationId: string
  ): Observable<ApplicationReviewItem[]> {
    return this.http.get<ApplicationReviewItem[]>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        '/export',
      {
        errorMessage: `Error retrieving application from the service for id = '${applicationId}'`,
        responseDecider: 'application',
      }
    );
  }

  /**
   * @param appId pass the selected applicationId
   * @description get the answers lists
   */
  public GetAnswers<T>(applicationId: string): Observable<T> {
    return this.http.get<T>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        '/answer',
      {
        errorMessage: `Error retrieving answers from the service for id = '${applicationId}'`,
      }
    );
  }

  /**
   * @param appId pass the selected applicationId
   * @param apiAnswer Answer
   * @param deletedTags List of tags to be deleted
   * @description save the answers
   */
  public SaveAnswers<T>(
    apiAnswer: T,
    applicationId: number,
    deletedTags: string[],
    showResponseMessage: boolean,
    etagAnswers: string
  ): Observable<T> {
    let subscriber = new Subscriber<T>();

    const observerable = new Observable<T>((s) => {
      subscriber = s;
    });

    const conditionalToaster =
      showResponseMessage === true
        ? {
            errorMessage: `Error saving answers for application = '${applicationId}'`,
            successMessage: 'Changes saved!',
          }
        : {};

    const requestBody = {
      answer: apiAnswer,
      deletedTags: deletedTags,
    };

    this.http
      .put<T>(
        `${this.baseUrl}/application/${encodeURIComponent(
          applicationId.toString()
        )}/Answer`,
        requestBody,
        conditionalToaster
      )
      .subscribe((a) => {
        subscriber.next(a);
      });

    return observerable;
  }

  // TODO -
  // public PostAnswers(applicationId: string): Observable<any> {
  //   return this.http.post<any>(
  //     this.baseUrl + '/answers/' + encodeURIComponent(applicationId),
  //     {
  //       errorMessage: `Error updating answers from the service for id = '${applicationId}'`,
  //     }
  //   )
  // }

  /**
   * @param apiAnswer Answer
   * @description get the notes from the question details
   */
  public GetNotes(applicationId: string): Observable<Array<ApplicationNote>> {
    return this.http.get<Array<ApplicationNote>>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        '/Notes',
      {
        errorMessage: `Error retrieving notes from the service for id = '${applicationId}'`,
      }
    );
  }

  /**
   * @param apiNotes note
   * @description save the notes
   */
  public SaveNote(apiNotes: ApplicationNote): Observable<any> {
    return this.http.post<ApplicationNote>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(apiNotes.id) +
        '/Notes',
      apiNotes,
      {
        errorMessage: `Error saving note for application = '${apiNotes.id}'`,
        successMessage: 'Note saved!',
      }
    );
  }

  /**
   * @param apiNotes note
   * @description Update the note
   */
  public UpdateNote(apiNotes: ApplicationNote): Observable<any> {
    return this.http.put<ApplicationNote>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(apiNotes.id) +
        '/notes/' +
        apiNotes.guid,
      apiNotes,
      {
        errorMessage: `Error updating note for application = '${apiNotes.id}'`,
        successMessage: 'Note Updated!',
      }
    );
  }

  /**
   * @param application pass the selected applicationId
   * @description insert the call logs like completed, To do etc.
   */
  public InsertCallLog<T>(application: any): Observable<any> {
    return this.http.post<T>(
      this.baseUrl +
        '/Application/' +
        encodeURIComponent(application.id) +
        '/callLog',
      application,
      {
        errorMessage: `Error saving call logs for application = '${application.id}'`,
      }
    );
  }

  /**
   * @param applicationId pass the selected applicationId
   * @description get the call log status
   */
  public GetCallLog<T>(applicationId: string): Observable<T> {
    return this.http.get<T>(
      this.baseUrl +
        '/Application/' +
        encodeURIComponent(applicationId) +
        '/callLog',
      {
        errorMessage: `Error getting call logs for application = '${applicationId}'`,
      }
    );
  }

  /**
   * @param applicationId Selected applicationId
   * @param QQTag current question tag
   * @param AQTag answer question tag
   * @description get the details of the current question
   */
  public GetDetailQuestions(
    applicationId: string,
    QQTag: string,
    AQTag: string,
    logFailure: boolean = true,
    scriptVersion?: ScriptVersion
  ): Observable<Array<DetailQuestion>> {
    const version = scriptVersion ? '/' + scriptVersion.startDate : '';
    return this.http.get<Array<DetailQuestion>>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        `/script/tag/${QQTag}/section/tag/${AQTag}/questions${version}`,
      {
        errorMessage: `Error retrieving detailQuestions from the service for id = '${applicationId}'`,
        donotLog: !logFailure,
      }
    );
  }

  /**
   * @param search search text for search appications
   * @description search the application
   * @returns application lists
   */
  public SearchApplication<T>(search: any): Observable<T[]> {
    return this.http.post<T[]>(this.baseUrl + '/application/search', search, {
      errorMessage: `Error search application'`,
    });
  }

  /**
   * @param stringToEncrypt change string text to encrypt
   * @description encrypt the text value
   */
  public EncryptValue<T>(stringToEncrypt: StringToEncrypt): Observable<T> {
    return this.http.post<T>(this.baseUrl + '/encrypt', stringToEncrypt, {
      errorMessage: `Error encrypting the value`,
    });
  }

  /**
   * @param applicationId Application ID
   * @description get the recall history
   */
  // Recall apis are starts from here
  public GetRecallHistory<T>(applicationId: string): Observable<T[]> {
    return this.http.get<T[]>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        '/Recalls',
      {
        errorMessage: `Error retrieving application from the service for id = '${applicationId}'`,
      }
    );
  }

  /**
   * @param recallData for insert the recall Data
   * @param appId Application Id
   * @description insert the recall questions
   */
  public insertRecallQuestions<T>(
    recallData: Recall,
    appId: string
  ): Observable<T> {
    return this.http.post<T>(
      this.baseUrl + '/application/' + encodeURIComponent(appId) + '/Recalls',
      recallData,
      {
        errorMessage: `Error in saving questions for recall = '${appId}'`,
        successMessage: 'Changes saved!',
      }
    );
  }

  /**
   * @param applicationId Application ID
   * @description get notification list
   */
  public getNotifications<T>(applicationId: string): Observable<T[]> {
    return this.http.get<T[]>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(applicationId) +
        '/sms',
      {
        errorMessage: `Error retrieving notification list from the service for id = '${applicationId}'`,
      }
    );
  }
  /* @param recallData for insert the recall Data
   * @param appId Application Id
   * @description insert the recall questions
   */
  public deleteRecall<T>(recallData: Recall, appId: string): Observable<T> {
    return this.http.delete<T>(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(appId) +
        '/Recalls/' +
        recallData.id +
        '/' +
        recallData.state,
      {
        errorMessage: `Error deleting the recall = '${appId}'`,
        successMessage: 'Changes saved!',
      }
    );
  }

  // Recall api's ends here

  public trackQuestionDuration<T>(
    model: ProcessorModel,
    duration: number
  ): Observable<T> {
    const url = `${this.baseUrl}/question/track`;
    const body = {
      applicationId: model.application.id,
      appointmentId: model.appointment.appointmentId,
      sectionName: model.question.section.name,
      questionName: model.question.name,
      durationInSeconds: duration,
      appTypeId: model.application.appType,
      verifierId: model.appointment.username,
      appointmentTypeId: model.appointment.callType,
      createdOn: new Date(),
    };
    return this.http.post<T>(url, body);
  }
}
