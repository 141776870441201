import { DiscoverableChainItem } from './discoverableChainItem';
export abstract class BaseAnswer {
  constructor(source: BaseAnswer) {
    if (source) {
      this.value = source.value;
      this.verification = source.verification;
      this.applicants = source.applicants;
      this.clientNo = source.clientNo;
      this.answerTag = source.answerTag;
      this.prompt = source.prompt;
      this.ezAppValue = source.ezAppValue;
      this.propertyType = source.propertyType;
      this.appointmentId = source.appointmentId;
      this.notes = source.notes;
      this.ignored = source?.ignored;
      this.questionId = source?.questionId;
    }
  }

  value: any; // 5' 11"
  verification: boolean;
  applicants: Array<string>;
  clientNo: string;
  answerTag: string;
  prompt: string;
  ezAppValue?: string;
  propertyType: string;
  appointmentId?: number;
  notes?: string;
  ignored?: boolean;
  questionId?: number;
}

export class Answer extends BaseAnswer {
  constructor(cosmosAnswer?: CosmosAnswer) {
    super(cosmosAnswer);
    if (cosmosAnswer) {
      Object.assign(this, cosmosAnswer);
    }
  }

  // chain: Array<DiscoverableChainItem>; // [{ tag : PrimaryApplicant }, { tag : height }]
}

export class CosmosAnswer extends BaseAnswer {
  constructor(answer: Answer) {
    super(answer);
  }
}
